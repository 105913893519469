import { useCallback, useEffect, useState } from 'react';

export enum ChromeEventActionTypes {
  StorylaneSharePageDemoReady = 'storylane-demo-ready',
  StorylaneShareUserId = 'storylane-share-user-id',
}

export function useListenForChromeExtensionMessages<
  EventPayload extends unknown
>({
  eventAction,
  postMessageAction,
}: {
  eventAction: string;
  postMessageAction?: string;
}) {
  const [eventData, setEventData] = useState<EventPayload | null>(null);

  const handleMessage = useCallback(
    (event: {
      data: {
        action: string;
        payload: EventPayload;
      };
    }) => {
      if (event.data?.action === eventAction) {
        setEventData(event.data.payload);
      }
    },
    [eventAction]
  );

  useEffect(() => {
    try {
      if (postMessageAction) {
        window.top?.postMessage(postMessageAction, '*');
      }

      window?.top?.addEventListener('message', handleMessage);
    } catch (error) {
      // no action needed
    }

    return () => {
      try {
        window?.top?.removeEventListener('message', handleMessage);
      } catch (error) {
        // no action needed
      }
    };
  }, [eventAction, handleMessage, postMessageAction]);

  return { eventData };
}
