import { DisplayConfigRendererBorder } from '../../features/project/project.types';
import { DemoPlayerSmScreemBottomSpace } from '../../features/ui/ui.constants';
import { getRendererImageFrameDimensions } from '../renderer/RendererImageFrame/RendererImageFrame';

export const getEmbedProjectImagePaddingBottom = (arg: {
  width: number;
  height: number;
  rendererBorder: DisplayConfigRendererBorder;
  isResponsive: boolean;
}) => {
  const { isResponsive, width, height, rendererBorder } = arg;
  const imageDemoFrameCompensation =
    getRendererImageFrameDimensions(rendererBorder);
  const imageDemoPaddingBottom = ((height * 100) / width).toFixed(2) + '%';

  return `calc(${imageDemoPaddingBottom} + ${
    isResponsive
      ? DemoPlayerSmScreemBottomSpace
      : imageDemoFrameCompensation.top +
        imageDemoFrameCompensation.bottom -
        imageDemoFrameCompensation.left -
        imageDemoFrameCompensation.right
  }px)`;
};
