import { User } from '../users/users.types';
import { BaseWidget } from '../widgets/widgets.types';
import { PageLinkId, PageLink } from '../pageLink/pageLink.types';
import { FlowId } from '../flow/flow.types';
import { Flowlist } from '../flowlist/flowlist.types';
import { ProjectKind, ProjectStatus } from './project.constants';
import {
  ProjectImageToken,
  ProjectImageTokenId,
} from '../projectImageToken/projectImageToken.types';
import { Page, ProjectPageId } from '../projectPages/projectPages.types';
import { GoogleFont } from '../../types/googlefonts.types';

export type ProjectId = string;
export type ProjectIds = ProjectId[];
type FolderId = string;
type TeamId = string;
type ThemeId = string;

/**
 * @see: {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/458807/Display+Config Docs}
 */
export type DisplayConfigType = {
  min_width_warning: boolean;
  min_width: number;
  full_screen: boolean;
  mobile_viewport: boolean;
  page_link_hints: boolean;
  page_link_hover_hints: boolean;
  page_link_toast_text: string;
  /**
   * Title displayed in `/share` page only for "image" projects.
   */
  renderer_title: string;
  renderer_border_color: DisplayConfigRendererBorder;
  renderer_dimensions: { width: number; height: number } | null;
  meta_tag_title: string;
  meta_tag_description: string;
  auto_play: number; // -1 = autoplay disabled, any positive number = timeout sec
  font: GoogleFont | null;
  nav_bar: boolean;
  nav_bar_color: string;
  replay: boolean;
  renderer_margin: string | null;
  renderer_background_color: string | null;
  cta_align: string;
  cta_background_color: string;
  cta_color: string;
  cta_emoji: string;
  cta_enabled: boolean;
  cta_text: string;
  cta_url: string;
  cta_url_target: '_blank' | '_self' | null;
};

export enum DisplayConfigRendererBorder {
  None = 'none',
  Light = 'light',
  Dark = 'dark',
}

export interface Project {
  id: ProjectId;
  name: string;
  pages_count: number;
  page_links: Record<PageLinkId, PageLink>;
  image_tokens: Record<ProjectImageTokenId, ProjectImageToken>; // @see https://storylane.atlassian.net/l/cp/0BsAzqi8
  widgets: BaseWidget[];
  views: number;
  folder_id: FolderId | null;
  links_count: number;
  published_at: string;
  edited_at: string;
  status: ProjectStatus;
  user: User;
  url: string;
  team_id: TeamId;
  widgets_count: number;
  flows_count: number;
  gif_url: string;
  flows: FlowId[];
  flowlists: Flowlist[];
  display_config: DisplayConfigType;
  kind: ProjectKind;
  lead_form_enabled: boolean;
  /**
   * @see {@link https://storylane.atlassian.net/l/cp/LVs4C416 Docs}
   */
  scripts: {
    id: string;
    position: 'head' | 'body' | 'iframe_head' | 'iframe_body';
    content: string;
  }[];
  embed_config: {
    inline?: EmbedInlineConfig;
    popup?: EmbedPopupConfig;
  } | null;
  start_page: Page;
  preview_page_id: ProjectPageId;
  theme_id: ThemeId | null;
}

export interface EmbedInlineConfig {
  button_color: string;
  border_color: string;
  text_color: string;
  cta_text: string;
  background_color: string;
  heading_text: string;
}

export interface EmbedPopupConfig {
  button_color: string;
  text_color: string;
  cta_text: string;
}

export interface ProjectLite {
  id: ProjectId;
  name: string;
  kind: ProjectKind;
}

export interface ProjectCtaStat {
  target_type: 'Widget' | 'FlowlistItem' | 'Project' | null;
  target_id: string;
  url: string;
  count: number;
}

export interface PresenterNoteResponse {
  id: string;
  presenter_notes: string | null;
  thumbnail_url: string;
  name: string;
}

export enum CustomCTAAlignment {
  BottomLeft = 'bottom_left',
  BottomRight = 'bottom_right',
  BottomCenter = 'bottom_center',
  TopLeft = 'top_left',
  TopRight = 'top_right',
  TopCenter = 'top_center',
}
