import { FallbackImageDimensions } from '../components/EmbedProjectImage/EmbedProjectImage';
import { DisplayConfigType } from '../features/project/project.types';

/**
 * Returns "final" renderer dimensions based on display config and optional first page dimensions.
 * The purpose of this utility function is to emphasize the importance of the renderer dimensions to the developer.
 * @see {@link https://storylane.atlassian.net/wiki/x/AQB9C Docs}
 *
 * @param projectDisplayConfig
 * @param projectPageFirstDimensions
 */
export function getFinalRendererDimensions(
  projectDisplayConfig: DisplayConfigType,
  projectPageFirstDimensions?: {
    width: number;
    height: number;
  }
): {
  width: number;
  height: number;
} {
  const dimensions = {
    width: 0,
    height: 0,
  };

  if (
    projectDisplayConfig.renderer_dimensions?.width &&
    projectDisplayConfig.renderer_dimensions.height
  ) {
    dimensions.width = projectDisplayConfig.renderer_dimensions.width;
    dimensions.height = projectDisplayConfig.renderer_dimensions.height;
  } else if (
    projectPageFirstDimensions &&
    projectPageFirstDimensions.width > 0 &&
    projectPageFirstDimensions.height > 0
  ) {
    dimensions.width = projectPageFirstDimensions.width;
    dimensions.height = projectPageFirstDimensions.height;
  } else {
    dimensions.width = FallbackImageDimensions.width;
    dimensions.height = FallbackImageDimensions.height;
  }

  return dimensions;
}

export default getFinalRendererDimensions;
