import { CSSProperties, useCallback, VFC } from 'react';

import {
  DisplayConfigRendererBorder,
  DisplayConfigType,
} from '../../features/project/project.types';
import { embedIframeName } from '../../features/ui/ui.constants';
import { getEmbedProjectImagePaddingBottom } from './getEmbedProjectImagePaddingBottom';

interface EmbedProjectImageProps {
  url: string;
  isResponsive: boolean;
  imageDimensions?: {
    width: number;
    height: number;
  };
  imageFrame?: DisplayConfigType['renderer_border_color'];
  testId?: string;
  id?: string;
}

const EmbedProjectImage: VFC<EmbedProjectImageProps> = ({
  imageDimensions,
  imageFrame = DisplayConfigRendererBorder.None,
  url,
  testId,
  isResponsive,
  id,
}) => {
  const style: CSSProperties = {
    position: 'relative',
  };

  const imageHeight = imageDimensions?.height || FallbackImageDimensions.height;
  const imageWidth = imageDimensions?.width || FallbackImageDimensions.width;

  style.paddingBottom = getEmbedProjectImagePaddingBottom({
    width: imageWidth,
    height: imageHeight,
    rendererBorder: imageFrame,
    isResponsive,
  });

  const handleIframeRef = useCallback((el: HTMLIFrameElement | null) => {
    el?.focus();
  }, []);

  return (
    <div style={style}>
      <iframe
        id={id}
        src={url}
        data-testid={testId}
        allow="fullscreen"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        name={embedIframeName}
        ref={handleIframeRef}
      />
    </div>
  );
};

export const FallbackImageDimensions = {
  width: 1280,
  height: 720,
};

export default EmbedProjectImage;
